import crypto from 'crypto'

const SECRET = process.env.COUNTRY_COOKIE_SECRET || 'supersecret'

function verifySignedCookie(signed) {
  if (!signed || typeof signed !== 'string') return null

  const [value, hash] = signed.split('.')
  if (!value || !hash) return null

  const hmac = crypto.createHmac('sha256', SECRET)
  hmac.update(value)
  const expected = hmac.digest('hex')

  return expected === hash ? value : null
}

export default function (context) {
  const signed = context.app.$cookies.get('ip-country')
  const verified = verifySignedCookie(signed)

  if (!verified) {
    console.warn('Tampered or missing ip-country cookie.')

    // Option 1: Redirect to homepage
    // context.redirect('/')

    // Option 2: Clear and reload (if client-side)
    context.app.$cookies.remove('ip-country')
    if (process.client) {
      window.location.reload()
    }

    // Option 3: You could also re-fetch geo data here
  }

  // Attach verified country to context for use in component
  context.app.$verifiedCountry = verified
}
