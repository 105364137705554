/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
      products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
        items {
          __typename
          uid
          brand
          sku
          name
          stock_status
          only_x_left_in_stock
          shots_stock
          expected_stock
          expected_stock_date
          fe_tax
          master_case_qty
          barcode
          status_akeneo
          exclude_for
          outlet
          thumbnail {
            url
            position
            disabled
            label
          }
          media_gallery{
            url
          }
          url_key
          url_rewrites {
            url
          }
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
          ... on GroupedProduct {
            items {
              product {
                sku
              }
            }
          }
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        total_count
      }
  }
`;
