import { Middleware } from '@nuxt/types'

const checkIpAddr = async (context) => {
  const data = await context.app.$axios.get('https://api.ipify.org?format=json')
  return data.data.ip
}

const checkCountry = async (context, ip: string) => {
  const { data } = await context.app.$axios.get(`http://ip-api.com/json/${ip}`)
  return data.countryCode
}

const checkAvailStores = async (context) => {
  const { data } = await context.app.$vsf.$magento.api.availableStores({ availableStores: 'availableStores' }, {})
  return data
}

const changeStore = (context, store_code, availStores) => {
  const store = availStores.availableStores.find(s => s.code === store_code && s.store_code === store_code)
  context.app.$vsf.$magento.config.state.setStore(store.store_code)
  context.app.$vsf.$magento.config.state.setCurrency(store.default_display_currency_code)
  context.app.$vsf.$magento.config.state.setLocale(store.store_code)

  const newStoreUrl = '/' + store.store_code
  context.redirect(newStoreUrl)

  if (process.client) {
    window.location.reload()
  }
}

const middleware: Middleware = async (context) => {
  const existing = context.app.$cookies.get('ip-country')
  if (existing) return // already set

  const ip = await checkIpAddr(context)
  const countryCode = await checkCountry(context, ip)
  const availStores = await checkAvailStores(context)

  // Send country to secure server middleware to set the signed cookie
  await context.app.$axios.post('/api/set-country', {
    countryCode
  })

  switch (countryCode) {
    case 'NL':
      changeStore(context, 'nl', availStores)
      break
    case 'FR':
      changeStore(context, 'fr', availStores)
      break
    case 'DE':
      changeStore(context, 'de', availStores)
      break
    case 'ES':
      changeStore(context, 'es', availStores)
      break
    default:
      changeStore(context, 'en', availStores)
      break
  }
}

export default middleware
